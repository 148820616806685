

























































import {Component, Prop, Vue, Watch} from "vue-property-decorator";
import {CustomerContact} from "@/models/customer/CustomerContact";
import {ValidationObserver, ValidationProvider} from "vee-validate";
import VuePerfectScrollbar from 'vue-perfect-scrollbar';
import vSelect from 'vue-select'
import {CategoryType} from "@/models/category/CategoryType";
import CategorySelect from "@/components/category/CategorySelect.vue";
import {CustomerService} from "@/services/customer/CustomerService";
import {ActionResult} from "@/models/ActionResult";
import cloneDeep from 'lodash/cloneDeep';
import {FileResult} from "@/components/upload-file/FileResult";
import CustomImage from "@/components/image/CustomImage.vue";
import UploadFileButton from "@/components/upload-file/UploadFileButton.vue";


@Component({
    components: {
        ValidationProvider,
        ValidationObserver,
        VuePerfectScrollbar,
        vSelect,
        CategorySelect,
        CustomImage,
        UploadFileButton
    }
})
export default class CustomerContactForm extends Vue {
    @Prop() customerId!: string;
    isShow = false;
    readonly = false;
    isLoading = false;

    categoryType = CategoryType;
    contact: CustomerContact | null = null;
    settings = {
        maxScrollbarLength: 60,
        wheelSpeed: .60,
    };

    @Watch('isLoading')
    onLoading(value: boolean) {
        if (value) {
            this.$vs.loading({
                background: 'primary',
                color: '#fff',
                container: '#btnSaveContact',
                scale: 0.45
            })
        } else {
            this.$vs.loading.close('#btnSaveContact > .con-vs-loading')
        }
    }

    get customerImage() {
        if (this.contact && this.contact.image) {
            return `${process.env.VUE_APP_GATEWAY_URL}files/${this.contact.image}`;
        } else {
            return '';
        }
    }

    get selectedRole() {
        return this.contact != null && this.contact.roleId ? [{
            id: this.contact.roleId,
            text: this.contact.roleName
        }] : [];
    }

    onRoleSelected(categories: any) {
        if (categories && categories.length > 0 && this.contact) {
            this.contact.roleId = categories[0].id;
            this.contact.roleName = categories[0].text;
        }
    }

    onImageChanged(data: FileResult[]) {
        if (this.contact) {
            const image = data[0];
            this.contact.image = `${image.id}`;
        }
    }

    edit(contact: CustomerContact) {
        this.isShow = true;
        this.contact = cloneDeep(contact);
    }

    save() {
        var customerService = new CustomerService();
        if (this.contact != null && this.contact !== undefined) {
            this.isLoading = true;
            customerService.UpdateContact(this.customerId, this.contact)
                .then((result: ActionResult) => {
                    this.isLoading = false;
                    if (result.code > 0) {
                        this.$vs.notify({
                            color: 'success',
                            title: 'Thông báo',
                            text: result.message
                        });
                        this.$emit('updated', cloneDeep(this.contact));
                        this.isShow = false;
                    } else {
                        this.$vs.notify({
                            color: 'danger',
                            title: 'Thông báo',
                            text: result.message
                        });
                    }
                })
                .catch((e: any) => {
                    this.isLoading = false;
                    this.$vs.notify({
                        color: 'danger',
                        title: 'Thông báo',
                        text: e.message
                    })
                })
        }
    }
}
