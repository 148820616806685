var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('vs-popup',{staticClass:"customer-contact-form",attrs:{"title":"Cập nhật thông tin liên hệ","active":_vm.isShow},on:{"update:active":function($event){_vm.isShow=$event}}},[_c('ValidationObserver',{scopedSlots:_vm._u([{key:"default",fn:function(ref){
var invalid = ref.invalid;
return [(_vm.contact != null && _vm.contact !== 'undefined')?_c('form',{on:{"submit":function($event){$event.preventDefault();return _vm.save.apply(null, arguments)}}},[_c('VuePerfectScrollbar',{key:_vm.$vs.rtl,ref:"mainSidebarPs",staticClass:"scroll-area--nofications-dropdown p-0 mb-10",attrs:{"settings":_vm.settings}},[_c('div',{staticClass:"flex"},[_c('label',{staticClass:"vs-select--label mt-3 w-1/3",attrs:{"for":""}},[_vm._v(_vm._s(_vm.$t('Avatar')))]),_c('div',{staticClass:"relative customer-image w-2/3",attrs:{"slot":"media"},slot:"media"},[_c('div',[_c('CustomImage',{staticClass:"w-full",attrs:{"src":_vm.customerImage}}),_c('div',{staticClass:"wrapper-image-button"},[_c('UploadFileButton',{on:{"uploaded":_vm.onImageChanged}})],1)],1)])]),_c('ValidationProvider',{attrs:{"name":_vm.$t('Contact name'),"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('vs-input',{staticClass:"w-full",attrs:{"label":_vm.$t('Contact name'),"placeholder":"$t('Enter contact name')"},model:{value:(_vm.contact.name),callback:function ($$v) {_vm.$set(_vm.contact, "name", $$v)},expression:"contact.name"}})]}}],null,true)}),_c('div',{staticClass:"mt-3"},[_c('ValidationProvider',{attrs:{"name":_vm.$t('Contact role')},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('label',{staticClass:"vs-select--label mt-3",attrs:{"for":""}},[_vm._v(_vm._s(_vm.$t('Contact role')))]),_c('CategorySelect',{attrs:{"type":_vm.categoryType.ContactRole,"placeholder":'Chọn vai trò người liên hệ.',"listSelected":_vm.selectedRole},on:{"select":_vm.onRoleSelected}})]}}],null,true)})],1),_c('ValidationProvider',{attrs:{"name":_vm.$t('Phone number')},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('vs-input',{staticClass:"w-full mt-3",attrs:{"label":_vm.$t('Phone number'),"placeholder":""},model:{value:(_vm.contact.phoneNumber),callback:function ($$v) {_vm.$set(_vm.contact, "phoneNumber", $$v)},expression:"contact.phoneNumber"}})]}}],null,true)}),_c('div',[_c('vs-input',{staticClass:"w-full mt-3",attrs:{"label":"Email","placeholder":""},model:{value:(_vm.contact.email),callback:function ($$v) {_vm.$set(_vm.contact, "email", $$v)},expression:"contact.email"}})],1)],1),_c('vs-row',{staticClass:"mt-5"},[_c('vs-col',{staticClass:"text-right"},[(!_vm.readonly)?_c('vs-button',{staticClass:"mr-1 vs-con-loading__container",attrs:{"id":"btnSaveContact","color":"primary","disabled":invalid || _vm.isLoading},on:{"click":_vm.save}},[_vm._v(" "+_vm._s(_vm.$t('Save'))+" ")]):_vm._e(),_c('vs-button',{attrs:{"color":"primary","type":"flat"},on:{"click":function($event){_vm.isShow = false}}},[_vm._v(" "+_vm._s(_vm.$t('Cancel'))+" ")])],1)],1)],1):_vm._e()]}}])})],1)}
var staticRenderFns = []

export { render, staticRenderFns }